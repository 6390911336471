import OneSignalVuePlugin, { useOneSignal } from '@onesignal/onesignal-vue3';

export default defineNuxtPlugin(nuxtApp => {
  nuxtApp.vueApp.use(OneSignalVuePlugin, {
    ...useRuntimeConfig().public.oneSignal,
    allowLocalhostAsSecureOrigin: true,
  });

  return {
    provide: {
      oneSignal: useOneSignal(),
    },
  };
});
