import { defineStore } from 'pinia';
import type {
  IPlayerBonus,
  IBonusCode,
  IWebSocketResponse,
  IGame,
  IPlayerFreeSpin,
  IPlayerCashback,
  IBonus,
  IPaginationMeta,
  CIFreeSpinIssuedAlert,
} from '~/types';
import debounce from 'lodash/debounce.js';

interface IBonusState {
  bonusCodeSubscription: any;
  bonusSubscription: any;
  freeSpinsSubscription: any;
  depositBonusCode: Maybe<IBonusCode>;
  selectedDepositBonus: IBonus | undefined;
  bonusDeclined: boolean;
  depositMoreInfoBonus: Maybe<Record<string, any>>;
  showDepositBonusCode: boolean;
  walletDepositBonus:
    | {
        id: string;
        packageId: string | undefined;
        amount?: number;
      }
    | undefined;
  cashBonuses: {
    data: IPlayerBonus[];
    meta: IPaginationMeta | undefined;
  };
  cashbackBonuses: IPlayerCashback[];
  freeSpins: {
    data: IPlayerFreeSpin[];
    meta: IPaginationMeta | undefined;
  };
  availableDepositBonuses: IBonus[];
  unavailableDepositBonuses: IBonus[];
  processingBonuses: string[];
}

export const useBonusStore = defineStore('bonusStore', {
  state: (): IBonusState => ({
    bonusCodeSubscription: undefined,
    bonusSubscription: undefined,
    freeSpinsSubscription: undefined,
    depositBonusCode: undefined,
    selectedDepositBonus: undefined,
    bonusDeclined: false,
    depositMoreInfoBonus: undefined,
    showDepositBonusCode: false,
    walletDepositBonus: undefined,
    cashBonuses: {
      data: [],
      meta: undefined,
    },
    cashbackBonuses: [],
    freeSpins: {
      data: [],
      meta: undefined,
    },
    availableDepositBonuses: [],
    unavailableDepositBonuses: [],
    processingBonuses: [],
  }),

  getters: {
    activePlayerBonus(state): IPlayerBonus | undefined {
      return state.cashBonuses.data.find(playerBonus => playerBonus.status === 2);
    },

    activePlayerFreeSpins(state): IPlayerFreeSpin[] {
      return state.freeSpins.data.filter(playerFreeSpin => playerFreeSpin.status === 2);
    },

    availableDepositMixedBonuses(state): IBonus[][] {
      const finalBonusesArray: IBonus[][] = [];

      state.availableDepositBonuses.forEach(bonus => {
        if (!bonus.package?.id) finalBonusesArray.push([bonus]);
        else {
          const findIndex = finalBonusesArray.findIndex(
            finalBonusesItem => finalBonusesItem[0].package?.id === bonus.package?.id
          );
          if (findIndex === -1) finalBonusesArray.push([bonus]);
          else finalBonusesArray[findIndex].push(bonus);
        }
      });

      return finalBonusesArray;
    },

    unavailableDepositMixedBonuses(state): IBonus[][] {
      const walletStore = useWalletStore();
      if (walletStore.successDepositCount === undefined) return [];
      const finalBonusesArray: IBonus[][] = [];
      const dayjs = useDayjs();

      state.unavailableDepositBonuses.forEach(bonus => {
        const fromDeposit = bonus.triggerConditions?.invoiceNumberFrom;
        const depositNumberWrong = !fromDeposit || fromDeposit <= (walletStore.successDepositCount ?? 0);
        const availabilityWrong = !bonus.availabilityAt || dayjs(bonus.availabilityAt).isSameOrBefore(dayjs());
        if (depositNumberWrong && availabilityWrong) return;

        if (!bonus.package?.id) finalBonusesArray.push([bonus]);
        else {
          const findIndex = finalBonusesArray.findIndex(
            finalBonusesItem => finalBonusesItem[0].package?.id === bonus.package?.id
          );
          if (findIndex === -1) finalBonusesArray.push([bonus]);
          else finalBonusesArray[findIndex].push(bonus);
        }
      });

      return finalBonusesArray;
    },

    bonusesCount(state): number {
      return (
        this.availableDepositMixedBonuses.length +
        this.unavailableDepositMixedBonuses.length +
        (state.freeSpins.meta?.totalRows ?? 0) +
        (state.cashBonuses.meta?.totalRows ?? 0) +
        state.cashbackBonuses.length
      );
    },
  },

  actions: {
    async getCashBonuses(page = 1): Promise<void> {
      const { activeAccount } = useWalletStore();
      if (!activeAccount?.currency) return;
      const { getPlayerBonuses } = useCoreBonusApi();
      const { data, meta } = await getPlayerBonuses({
        status: [1, 2, 4],
        currency: [activeAccount.currency],
        sortBy: 'activatedAt',
        sortOrder: 'desc',
        page,
        perPage: window && window.innerWidth < 1024 ? 6 : 12,
      });

      this.cashBonuses.data = page === 1 ? data : [...this.cashBonuses.data, ...data];
      this.cashBonuses.meta = meta;
    },

    async getFreeSpins(page = 1): Promise<void> {
      const { activeAccount } = useWalletStore();
      if (!activeAccount?.currency) return;
      const { getPlayerFreeSpins } = useCoreBonusApi();
      const { data, meta } = await getPlayerFreeSpins({
        status: [1, 2, 4],
        currency: [activeAccount.currency],
        sortBy: 'activatedAt',
        sortOrder: 'desc',
        page,
        perPage: window && window.innerWidth < 1024 ? 6 : 12,
      });
      this.freeSpins.data = page === 1 ? data : [...this.freeSpins.data, ...data];
      this.freeSpins.meta = meta;
    },

    async updateActiveBonuses(): Promise<void> {
      const { activeAccount } = useWalletStore();
      if (!activeAccount?.currency) return;

      const { getPlayerBonuses } = useCoreBonusApi();
      const { data } = await getPlayerBonuses({
        status: [2],
        currency: [activeAccount.currency],
      });

      if (data.length) {
        this.cashBonuses.data = this.cashBonuses.data.map(playerBonus => {
          const findNewBonus = data.find(bonus => bonus.id === playerBonus.id);
          return findNewBonus || playerBonus;
        });
      }
    },

    async updateActiveFreeSpins(): Promise<void> {
      const { activeAccount } = useWalletStore();
      if (!activeAccount?.currency) return;

      const { getPlayerFreeSpins } = useCoreBonusApi();
      const { data } = await getPlayerFreeSpins({
        status: [2],
        currency: [activeAccount.currency],
      });

      if (data.length) {
        this.freeSpins.data = this.freeSpins.data.map(playerFreeSpin => {
          const findNewFreeSpin = data.find(freeSpin => freeSpin.id === playerFreeSpin.id);
          return findNewFreeSpin || playerFreeSpin;
        });
      }
    },

    async getCashbackBonuses(): Promise<void> {
      const { activeAccount } = useWalletStore();
      if (!activeAccount?.currency) return;
      const { getPlayerCashback } = useCoreBonusApi();
      const { data } = await getPlayerCashback(activeAccount.currency);
      this.cashbackBonuses = data;
    },

    async getDepositBonuses(): Promise<void> {
      const { activeAccount } = useWalletStore();
      if (!activeAccount?.currency) return;
      const { getDepositBonuses } = useCoreBonusApi();
      const [availableDepositBonuses, allDepositBonuses] = await Promise.all([
        getDepositBonuses({ currency: activeAccount.currency }),
        getDepositBonuses({ currency: activeAccount.currency, skipCondition: ['1', '2'] }),
      ]);
      this.availableDepositBonuses = availableDepositBonuses;
      this.unavailableDepositBonuses = allDepositBonuses.filter(
        allBonusItem => !availableDepositBonuses.some(availableBonus => availableBonus.id === allBonusItem.id)
      );
    },

    async getDepositBonusCode(): Promise<void> {
      const { getBonusCodes } = useCoreBonusApi();

      const bonusCodeResponse = await getBonusCodes(3);
      this.depositBonusCode = bonusCodeResponse[0] || undefined;
    },

    showBonusCodeNotification(status?: number): void {
      const { showAlert } = useLayoutStore();
      const { alertsData, defaultLocaleAlertsData } = useGlobalStore();

      if (status === 3) {
        showAlert(alertsData?.bonus?.bonusCodeIncorrect || defaultLocaleAlertsData?.bonus?.bonusCodeIncorrect);
      } else if (status === 4) {
        showAlert(alertsData?.bonus?.bonusCodeNotAvailable || defaultLocaleAlertsData?.bonus?.bonusCodeNotAvailable);
      }
    },

    subscribeBonusCodeSocket(): void {
      const profileStore = useProfileStore();
      if (profileStore.profile?.id) {
        const { createSubscription } = useWebSocket();
        this.bonusCodeSubscription = createSubscription(
          `bonus:player-bonus-codes#${profileStore.profile?.id}`,
          this.bonusCodeSocketTrigger
        );
      }
    },

    subscribeBonusSocket(): void {
      const profileStore = useProfileStore();
      if (profileStore.profile?.id) {
        const { createSubscription } = useWebSocket();
        this.bonusSubscription = createSubscription(
          `bonus:player-bonuses#${profileStore.profile?.id}`,
          this.bonusesSocketTrigger
        );
      }
    },

    subscribeFreeSpinsSocket(): void {
      const profileStore = useProfileStore();
      if (profileStore.profile?.id) {
        const { createSubscription } = useWebSocket();
        this.freeSpinsSubscription = createSubscription(
          `bonus:player-freespins#${profileStore.profile?.id}`,
          this.freeSpinsSocketTrigger
        );
      }
    },

    bonusCodeSocketTrigger(webSocketResponse: IWebSocketResponse): void {
      const bonusCodeData: Maybe<IBonusCode> = webSocketResponse.data.playerBonusCode;
      this.showBonusCodeNotification(bonusCodeData?.status);
    },

    updatePlayerBonusList: debounce(
      async (bonusData: IPlayerBonus, thisStore: any) => {
        if ([1, 2, 4].includes(bonusData.status)) await thisStore.getCashBonuses();
        else {
          const newCashBonuses: IPlayerBonus[] = [];
          thisStore.cashBonuses.data.forEach((bonus: IPlayerBonus) => {
            if (bonus.id !== bonusData.id) newCashBonuses.push(bonus);
          });
          thisStore.cashBonuses.data = newCashBonuses;
        }
      },
      500,
      { leading: false }
    ),

    updatePlayerFreeSpinsList: debounce(
      async (freeSpinData: IPlayerFreeSpin, thisStore: any) => {
        if ([1, 2, 4].includes(freeSpinData.status)) await thisStore.getFreeSpins();
        else {
          const newFreeSpins: IPlayerFreeSpin[] = [];
          thisStore.freeSpins.data.forEach((freeSpin: IPlayerFreeSpin) => {
            if (freeSpin.id !== freeSpinData.id) newFreeSpins.push(freeSpin);
          });
          thisStore.playerFreeSpins.data = newFreeSpins;
        }
      },
      500,
      { leading: false }
    ),

    bonusesSocketTrigger(webSocketResponse: IWebSocketResponse): void {
      const bonusData: Maybe<IPlayerBonus> = webSocketResponse.data.playerBonus;
      if (!bonusData) return;

      const { showAlert } = useLayoutStore();
      const { alertsData, defaultLocaleAlertsData } = useGlobalStore();
      const { formatAmount } = useProjectMethods();
      const { status, result } = bonusData;
      const formattedAmount = formatAmount(bonusData.currency, bonusData.amount);

      const alertsKey: { [key: string]: string } = {
        // key - '{status}-{result}'
        '1-1': 'bonusIssued',
        '2-1': 'bonusActivated',
        '3-2': 'bonusPlayed',
        '3-3': 'bonusCanceled',
        '3-4': 'bonusExpired',
        '3-5': 'bonusLost',
      };

      const transformMessage = (message?: string): string => {
        if (!message) return '';
        return message.replace('{amount}', `<b>${formattedAmount}</b>`);
      };

      const alertMessage =
        alertsData?.bonus?.[alertsKey[`${status}-${result}`]] ||
        defaultLocaleAlertsData?.bonus?.[alertsKey[`${status}-${result}`]];

      this.updatePlayerBonusList(bonusData, this);
      if (alertMessage) showAlert(transformMessage(alertMessage));
    },

    async freeSpinsSocketTrigger(webSocketResponse: IWebSocketResponse): Promise<void> {
      const freeSpinData: Maybe<IPlayerFreeSpin> = webSocketResponse.data.playerFreespin;
      if (!freeSpinData) return;

      const { showAlert } = useLayoutStore();
      const { alertsData, defaultLocaleAlertsData } = useGlobalStore();
      const { count, currency, gameId, status, result } = freeSpinData;

      const alertsKey: { [key: string]: string } = {
        // key - '{status}-{result}'
        '1-1': 'freeSpinIssued',
        '2-1': 'freeSpinActivated',
        '3-2': 'freeSpinPlayed',
        '3-3': 'freeSpinCanceled',
        '3-4': 'freeSpinExpired',
      };

      const { getGamesInfo } = useCoreGamesApi();
      const { localizePath } = useProjectMethods();
      let gameInfo: IGame;
      try {
        gameInfo = await getGamesInfo(gameId);
      } catch {
        console.error('Something went wrong with game info fetching!');
      }

      const transformMessage = (message?: string, gameLinkParam?: 'game' | 'bonuses'): string => {
        if (!message) return '';

        let editedMessage = message.replace('{count}', `<b>${count} free spins</b>`);
        editedMessage = editedMessage.replace('{currency}', `<b>${currency}</b>`);

        let gameLink: string | undefined;
        if (gameLinkParam === 'bonuses') gameLink = localizePath('/profile/bonuses');
        else if (gameInfo) gameLink = localizePath(`/games/${gameInfo.identity}?real=true`);

        editedMessage = editedMessage.replace('{game}', gameLink ? `<a href="${gameLink}">${gameInfo.name}</a>` : '');
        return editedMessage;
      };

      const alertData: string | CIFreeSpinIssuedAlert | undefined =
        alertsData?.freeSpin?.[alertsKey[`${status}-${result}`]] ||
        defaultLocaleAlertsData?.freeSpin?.[alertsKey[`${status}-${result}`]];

      this.updatePlayerFreeSpinsList(freeSpinData, this);
      if (alertData && (alertData as CIFreeSpinIssuedAlert)?.description) {
        showAlert(
          transformMessage(
            (alertData as CIFreeSpinIssuedAlert).description,
            (alertData as CIFreeSpinIssuedAlert)?.gameLink
          )
        );
      } else if (alertData) {
        showAlert(transformMessage(alertData as string));
      }
    },

    unsubscribeBonusCodeSocket(): void {
      if (this.bonusCodeSubscription) {
        this.bonusCodeSubscription.unsubscribe();
        this.bonusCodeSubscription.removeAllListeners();
      }
    },

    unsubscribeBonusSocket(): void {
      if (this.bonusSubscription) {
        this.bonusSubscription.unsubscribe();
        this.bonusSubscription.removeAllListeners();
      }
    },

    unsubscribeFreeSpinsSocket(): void {
      if (this.freeSpinsSubscription) {
        this.freeSpinsSubscription.unsubscribe();
        this.freeSpinsSubscription.removeAllListeners();
      }
    },
  },
});
