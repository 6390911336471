import { defineStore } from 'pinia';
import type { IAccount, IInvoiceStatistics, ISocketInvoice, IWebSocketResponse } from '~/types';

interface IWalletState {
  accounts: IAccount[];
  depositMethods: any[];
  withdrawMethods: any[];
  requestTimer: any;
  accountSubscription: any;
  invoicesSubscription: any;
  depositLimitError: boolean;
  accountSwitching: Promise<any> | undefined;
  requestPaymentMethodsRegion: Maybe<string>;
  selectedPaymentMethodsRegion: Maybe<string>;
  invoicesStatistics: IInvoiceStatistics[] | undefined;
}

export const useWalletStore = defineStore('walletStore', {
  state: (): IWalletState => ({
    accounts: [],
    depositMethods: [],
    withdrawMethods: [],
    requestTimer: '',
    accountSubscription: undefined,
    invoicesSubscription: undefined,
    depositLimitError: false,
    accountSwitching: undefined,
    requestPaymentMethodsRegion: undefined,
    selectedPaymentMethodsRegion: undefined,
    invoicesStatistics: undefined,
  }),

  getters: {
    activeAccount(state): Maybe<IAccount> {
      return state.accounts.find(acc => acc.status === 1);
    },

    successDepositCount(state): number | undefined {
      if (!state.invoicesStatistics) return undefined;
      return state.invoicesStatistics.reduce((accCount, currentStatisticItem) => {
        return accCount + currentStatisticItem.depositsCount;
      }, 0);
    },
  },

  actions: {
    async getPaymentStatistics(): Promise<void> {
      const { getInvoicesStatistics } = useCoreWalletApi();
      this.invoicesStatistics = await getInvoicesStatistics();
    },

    async getUserAccounts(): Promise<void> {
      const { getAccounts } = useCoreWalletApi();
      this.accounts = await getAccounts();
    },

    setPaymentMethodsGeo(): void {
      const storageGeo = localStorage.getItem('paymentGeo');
      const globalStore = useGlobalStore();
      const programmaticGeo = storageGeo || globalStore.headerCountry;

      if (!programmaticGeo) this.selectedPaymentMethodsRegion = undefined;
      else {
        const globalStore = useGlobalStore();
        this.selectedPaymentMethodsRegion = globalStore.countries?.find(
          country => country.code === programmaticGeo
        )?.code;
      }

      this.requestPaymentMethodsRegion =
        this.selectedPaymentMethodsRegion !== globalStore.headerCountry ? this.selectedPaymentMethodsRegion : undefined;
    },

    async getDepositMethods(): Promise<void> {
      this.depositLimitError = false;
      const { getDepositMethods } = useCoreWalletApi();

      try {
        this.depositMethods = await getDepositMethods(
          this.activeAccount?.currency || '',
          this.requestPaymentMethodsRegion
        );
      } catch (err: any) {
        this.depositMethods = [];

        if (err.data?.error?.code === 13100) {
          this.depositLimitError = true;
          const { getLimits } = useLimitsStore();
          await getLimits();
        }
      }
    },

    async getWithdrawMethods(): Promise<void> {
      const { getWithdrawMethods } = useCoreWalletApi();
      this.withdrawMethods = await getWithdrawMethods(
        this.activeAccount?.currency || '',
        this.requestPaymentMethodsRegion
      );
    },

    subscribeAccountSocket(): void {
      const profileStore = useProfileStore();
      if (profileStore.profile?.id) {
        const { createSubscription } = useWebSocket();
        this.accountSubscription = createSubscription(
          `wallet:accounts#${profileStore.profile?.id}`,
          this.updateAccount
        );
      }
    },

    unsubscribeAccountSocket(): void {
      if (this.accountSubscription) {
        this.accountSubscription.unsubscribe();
        this.accountSubscription.removeAllListeners();
      }
    },

    updateAccount(webSocketResponse: IWebSocketResponse): void {
      const accountData: Maybe<IAccount> = webSocketResponse.data.account;
      this.accounts = this.accounts.map(account => {
        if (account.id === accountData?.id) return accountData;
        return account;
      });
    },

    subscribeInvoicesSocket(): void {
      const profileStore = useProfileStore();
      if (profileStore.profile?.id) {
        const { createSubscription } = useWebSocket();
        this.invoicesSubscription = createSubscription(
          `payment:invoices#${profileStore.profile?.id}`,
          this.showInvoiceStatus
        );
      }
    },

    asyncInvoiceProcessing(invoiceData: ISocketInvoice | undefined): void {
      if (!invoiceData || invoiceData.status !== 1 || !invoiceData.publicData) return;

      const sessionInvoice = sessionStorage.getItem('asyncInvoiceId');
      if (sessionInvoice && sessionInvoice === invoiceData.id) {
        const showAwaitInvoiceBlock = document.getElementById('wallet-await-invoice');
        if (showAwaitInvoiceBlock && invoiceData.publicData.qr) useEvent('receivedAsyncInvoice', invoiceData);
        sessionStorage.removeItem('asyncInvoiceId');
      }
    },

    showInvoiceStatus(webSocketResponse: IWebSocketResponse): void {
      const socketInvoiceData = webSocketResponse.data?.invoice;
      this.asyncInvoiceProcessing(socketInvoiceData);
      if (![2, 3].includes(socketInvoiceData?.status || -1)) return;

      const { getContent, formatAmount } = useProjectMethods();
      const { alertsData, defaultLocaleAlertsData, currencies } = useGlobalStore();
      const { showAlert } = useLayoutStore();
      const invoiceUtcDate = new Date(socketInvoiceData?.createdAt || '');
      const invoiceDate = invoiceUtcDate.toLocaleString().slice(0, 10);
      const eventAmount = socketInvoiceData?.amount;
      const eventCurrency = socketInvoiceData?.currency;
      const formattedSum = formatAmount(eventCurrency, eventAmount);
      const invoiceSuccess = socketInvoiceData?.status === 2;
      const eventCurrencyObject = currencies.find(currency => currency.code === eventCurrency);

      const formattedDescription = (cmsMessage: string | undefined): string => {
        if (!cmsMessage) return '';
        const formattedMessage = cmsMessage.replace('{sum}', formattedSum);
        return formattedMessage.replace('{date}', invoiceDate);
      };

      if (webSocketResponse.data?.event === 'invoice.deposit.updated') {
        const { getDepositBonusCode, getDepositBonuses } = useBonusStore();
        getDepositBonusCode();
        getDepositBonuses();
        this.getPaymentStatistics();
        useEvent('depositInvoiceUpdated');

        const cmsMessage = invoiceSuccess
          ? getContent(alertsData, defaultLocaleAlertsData, 'wallet.depositSuccess')
          : getContent(alertsData, defaultLocaleAlertsData, 'wallet.depositError');
        const alertMessage = formattedDescription(cmsMessage);
        showAlert(alertMessage);

        useEvent('analyticsEvent', {
          event: invoiceSuccess ? 'walletDepositSuccess' : 'walletDepositFail',
          depositAmount: eventAmount,
          depositCurrency: eventCurrency,
          successDepositNumber: socketInvoiceData?.number,
          invoiceId: socketInvoiceData?.id,
          walletType: eventCurrencyObject?.type,
        });
      } else if (webSocketResponse.data?.event === 'invoice.withdrawal.updated') {
        const cmsMessage = invoiceSuccess
          ? getContent(alertsData, defaultLocaleAlertsData, 'wallet.withdrawSuccess')
          : getContent(alertsData, defaultLocaleAlertsData, 'wallet.withdrawError');
        const alertMessage = formattedDescription(cmsMessage);
        showAlert(alertMessage);

        useEvent('analyticsEvent', {
          event: invoiceSuccess ? 'walletWithdrawSuccess' : 'walletWithdrawFail',
          withdrawAmount: eventAmount,
          withdrawCurrency: eventCurrency,
          invoiceId: socketInvoiceData?.id,
          walletType: eventCurrencyObject?.type,
        });
      }
    },

    unsubscribeInvoiceSocket(): void {
      if (this.invoicesSubscription) {
        this.invoicesSubscription.unsubscribe();
        this.invoicesSubscription.removeAllListeners();
      }
    },
  },
});
