import type {
  IAvailableQuestsRequest,
  ILoyaltyLevel,
  ILoyaltyLevelsRequest,
  IPlayerLoyaltyAccount,
  IPlayerQuest,
  IPlayerQuestsRequest,
  IPlayerQuestsResponse,
  ITournament,
  ITournamentsRequest,
  ITournamentsResponse,
} from '../types';
import { useApiAuthInstance } from '../assets/apiAuthInstance';
import { useApiGuestInstance } from '~/core/assets/apiGuestInstance';

export const useCoreRetentionApi = () => {
  const getAvailableQuests = async (params?: IAvailableQuestsRequest): Promise<IPlayerQuestsResponse> => {
    return await useApiAuthInstance('/api/retention/quests', { params });
  };

  const getPlayerQuests = async (params?: IPlayerQuestsRequest): Promise<IPlayerQuestsResponse> => {
    return await useApiAuthInstance('/api/retention/player-quests', { params });
  };

  const activatePlayerQuest = async (playerQuestId: string): Promise<IPlayerQuest> => {
    const { data } = await useApiAuthInstance(`/api/retention/quests/${playerQuestId}/activate`, { method: 'PUT' });
    return data;
  };

  const cancelPlayerQuest = async (playerQuestId: string): Promise<IPlayerQuest> => {
    const { data } = await useApiAuthInstance(`/api/retention/player-quests/${playerQuestId}/cancel`, {
      method: 'PUT',
    });
    return data;
  };

  const getPlayerLoyaltyAccount = async (): Promise<IPlayerLoyaltyAccount> => {
    const { data } = await useApiAuthInstance('/api/retention/account');
    return data;
  };

  const getLoyaltyLevels = async (params?: ILoyaltyLevelsRequest): Promise<ILoyaltyLevel[]> => {
    const { data } = await useApiGuestInstance('/api/retention/levels', { params });
    return data;
  };

  const getTournaments = async (params?: ITournamentsRequest): Promise<ITournamentsResponse> => {
    return await useApiGuestInstance('/api/retention/tournaments', { params });
  };

  const getTournament = async (params: { tournamentId: string; authorized: boolean }): Promise<ITournament> => {
    const { data } = params.authorized
      ? await useApiAuthInstance(`/api/retention/tournaments/${params.tournamentId}`)
      : await useApiGuestInstance(`/api/retention/tournaments/${params.tournamentId}`);
    return data;
  };

  const participateTournament = async (tournamentId: string): Promise<void> => {
    await useApiAuthInstance(`/api/retention/tournaments/${tournamentId}/participate`, {
      method: 'POST',
    });
  };

  return {
    getAvailableQuests,
    getPlayerQuests,
    activatePlayerQuest,
    cancelPlayerQuest,
    getPlayerLoyaltyAccount,
    getLoyaltyLevels,
    getTournaments,
    getTournament,
    participateTournament,
  };
};
