import get from 'lodash/get';
import * as projectRules from './validationRules';
import fieldsTypeMap from '~/maps/fieldsTypeMap.json';
import type {
  CIPageMeta,
  IAmountRangeItem,
  IBonus,
  IGameImages,
  IObserverOptions,
  IGameProvider,
  CITournamentPage,
  ITournament,
} from '~/types';
import dayjs from 'dayjs';

export const useProjectMethods = () => {
  const setFormData = (fields: any[]): any => {
    const formData: any = {};
    fields.forEach(field => {
      formData[field.name || field.key] = field.value || '';
    });
    return formData;
  };

  const createCustomMessage = (params: any, message: string): string => {
    let newMessage = message;
    if (Object.prototype.hasOwnProperty.call(params, 'min')) {
      newMessage = newMessage.replace(/\{min}/g, params.min);
    }
    if (Object.prototype.hasOwnProperty.call(params, 'max')) {
      newMessage = newMessage.replace(/\{max}/g, params.max);
    }

    if (Object.prototype.hasOwnProperty.call(params, 'param')) {
      newMessage = newMessage.replace(/\{param}/g, params.param);
    }

    return newMessage;
  };

  const createFormRules = (fieldsRules: any, projectRules: any, validationMessages: any): any => {
    const formRules: any = {};

    Object.keys(fieldsRules).forEach(field => {
      const rules: any = {};

      fieldsRules[field].forEach((item: any) => {
        if (!projectRules[item.rule]) {
          return;
        }

        if (item.arguments) {
          rules[item.rule] = projectRules.helpers.withMessage(
            ({ $params }: { $params: any }) => createCustomMessage($params, validationMessages[item.rule] || ''),
            projectRules[item.rule](item.arguments)
          );
        } else {
          rules[item.rule] = projectRules.helpers.withMessage(
            validationMessages[item.rule] || '',
            projectRules[item.rule]
          );
        }
      });
      formRules[field] = rules;
    });
    return formRules;
  };

  const createValidationRules = (fields: any[], includeContext?: boolean): any => {
    const validationRules: any = {};
    const fieldsType: any = fieldsTypeMap;

    if (includeContext) {
      fields.forEach(field => {
        if (field.isRequired) validationRules[field.name] = [{ rule: 'required' }];
        if (fieldsType[field.name]?.validation?.length) {
          if (validationRules[field.name]) {
            validationRules[field.name] = [...validationRules[field.name], ...fieldsType[field.name].validation];
          } else validationRules[field.name] = fieldsType[field.name].validation;
        }
      });
    } else {
      fields.forEach(field => {
        if (fieldsType[field.name]?.validation?.length) {
          validationRules[field.name] = fieldsType[field.name].validation;
        }
      });
    }
    return validationRules;
  };

  const getFormRules = (fieldsRules: any): any => {
    const { fieldsSettings, defaultLocaleFieldsSettings } = useGlobalStore();
    const messages = { ...defaultLocaleFieldsSettings?.validationMessages, ...fieldsSettings?.validationMessages };
    const { createFormRules } = useProjectMethods();
    return createFormRules(fieldsRules, projectRules, messages);
  };

  const preloaderDone = (): void => {
    const preloaderEl = document.querySelector('.preloader');
    if (preloaderEl?.classList.contains('is-none')) return;

    setTimeout(() => {
      preloaderEl?.classList.add('is-hide');
    }, 500);

    setTimeout(() => {
      preloaderEl?.classList.add('is-none');
    }, 1000);
  };

  const localizePath = (path: string | undefined): string => {
    const globalStore = useGlobalStore();
    if (globalStore.currentLocale?.code.toLowerCase() === globalStore.defaultLocale?.code.toLowerCase()) {
      if (!path) return '';
      return path.startsWith('/') ? path : `/${path}`;
    }
    return `/${globalStore.currentLocale?.code.toLowerCase()}${!path || path === '/' ? '' : path}`;
  };

  const createSrcSet = (src?: string): string => {
    if (!src) return '';

    const webpSrc = src.replace(/\.\w+$/, '.webp');
    return `${webpSrc}, ${src}`;
  };

  const getImageUrl = (customImages: Maybe<IGameImages>, images: Maybe<IGameImages>, orientation: string) => {
    let imageUrl: string | undefined;
    const { public: config } = useRuntimeConfig();

    if (customImages) {
      const imagePath =
        orientation === 'vertical'
          ? customImages['200x300']?.['3x'] || customImages['200x300']?.['2x'] || customImages['200x300']?.['1x']
          : customImages['200x200']?.['3x'] || customImages['200x200']?.['2x'] || customImages['200x200']?.['1x'];
      if (imagePath && config.customerCdn) imageUrl = `${config.customerCdn}${imagePath}`;
    }

    if (!imageUrl && images) {
      const imagePath =
        orientation === 'vertical'
          ? images['200x300']?.['3x'] || images['200x300']?.['2x'] || images['200x300']?.['1x']
          : images['200x200']?.['3x'] || images['200x200']?.['2x'] || images['200x200']?.['1x'];
      if (imagePath && config.gamehubCdn) imageUrl = `${config.gamehubCdn}${imagePath}`;
    }

    if (imageUrl) return imageUrl;
    return undefined;
  };

  const getNicknameFromEmail = (email?: string): string => {
    if (!email) return 'unknown';
    const getFirstPath = email.split('@')[0];
    if (getFirstPath.length < 4) return `${getFirstPath.slice(0, 1)}***`;
    return `${getFirstPath.slice(0, -3)}***`;
  };

  const formatAmount = (
    currency: Maybe<string>,
    amount: Maybe<number>,
    params?: { withoutCents?: boolean }
  ): string => {
    if (!currency) return '';
    const { currencies } = useGlobalStore();
    const currencyData = currencies.find(currencyData => currencyData.code === currency);
    if (!currencyData) return '';
    const formatedBalance = (amount ?? 0).toLocaleString('en-US', {
      minimumFractionDigits: params?.withoutCents ? 0 : String(currencyData.subunitToUnit).length - 1,
      maximumFractionDigits: String(currencyData.subunitToUnit).length - 1,
    });
    return `${currencyData.symbol}${formatedBalance}`;
  };

  const getMainBalanceFormat = (currency: string, amount: number): { currency: string; amount: number } => {
    const { currencies } = useGlobalStore();
    const currencyConfig = currencies.find(item => {
      if (!item.subCurrencies?.length) return item.code === currency;

      return item.subCurrencies.some(subCurrency => subCurrency.code === currency);
    });
    if (!currencyConfig) return { currency, amount };
    const specialCurrencies = ['BTC', 'ETH'];
    if (currencyConfig.type === 'fiat' || !specialCurrencies.includes(currencyConfig.code)) return { currency, amount };

    const subCurrencyConfig = currencyConfig.subCurrencies?.find(subCurrency => subCurrency.code === currency);
    if (amount === 0) return { currency: currencyConfig.code, amount };

    return { currency: currencyConfig.code, amount: amount / (subCurrencyConfig?.subunitToUnit || 1) };
  };

  const setPageMeta = (metaData: Maybe<CIPageMeta>): void => {
    const globalStore = useGlobalStore();
    const requestUrl = useRequestURL();
    const imageContent = metaData?.image || globalStore.globalSeo?.image;
    const imageUrl = imageContent ? `${requestUrl.origin}${imageContent}` : undefined;

    useSeoMeta({
      title: metaData?.title || globalStore.globalSeo?.title,
      ogTitle: metaData?.title || globalStore.globalSeo?.title,
      description: metaData?.description || globalStore.globalSeo?.description,
      ogDescription: metaData?.description || globalStore.globalSeo?.description,
      ogImage: imageUrl,
    });
  };

  const sortByAlphabet = (a: string, b: string): number => {
    if (a > b) return 1;
    if (a < b) return -1;
    return 0;
  };

  const getRandomInt = (min: number, max: number): number => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min)) + min;
  };

  const getContent = (contentData: any, defaultLocaleContentData: any, path: string): any =>
    get(contentData, path) ?? get(defaultLocaleContentData, path);

  const getEquivalentFromBase = (
    baseAmount: number | undefined,
    targetCurrencyCode: string | undefined
  ): {
    amount: number;
    currency: string;
  } => {
    const { currencies } = useGlobalStore();
    const targetCurrency = currencies.find(currency => currency.code === targetCurrencyCode);

    if (!targetCurrency || !baseAmount) return { amount: 0, currency: '' };

    const balance = (baseAmount * targetCurrency.rate.rate) / targetCurrency.subunitToUnit;
    return { amount: balance, currency: targetCurrency.code };
  };

  const getLocalesContentData = (
    currentLocaleContentResponse: any,
    defaultLocaleContentResponse: any
  ): {
    currentLocaleData: any;
    defaultLocaleData: any;
  } => {
    // I NEED PROXY IN RETURN
    const contentData = reactive({
      currentLocaleData: undefined,
      defaultLocaleData: undefined,
    });

    if (currentLocaleContentResponse.status !== 'rejected') {
      contentData.currentLocaleData = currentLocaleContentResponse.value;
    }

    if (defaultLocaleContentResponse.status !== 'rejected') {
      contentData.defaultLocaleData = defaultLocaleContentResponse.value;
    }

    return contentData;
  };

  const initObserver = (options: IObserverOptions): any => {
    const optionsThing = {
      once: options?.once || false,
      settings: options?.settings || { root: null, rootMargin: '0px', threshold: 0.05 },
    };

    const inviewEvent = new Event('inview', { bubbles: false, cancelable: true });
    const outviewEvent = new Event('outview', { bubbles: false, cancelable: true });

    const callback = (entries: any, observer: any) => {
      entries.forEach((entry: any) => {
        if (entry.isIntersecting) {
          entry.target.dispatchEvent(inviewEvent);
          if (optionsThing.once) observer.unobserve(entry.target);
        } else {
          entry.target.dispatchEvent(outviewEvent);
        }
      });
    };

    return new IntersectionObserver(callback, optionsThing.settings);
  };

  const replaceContent = (content: string, separator: string): string => {
    const pathArr = content?.split(separator);
    let newString = '';
    pathArr?.forEach((path, index) => {
      if (index % 2) {
        newString += `<span>${path}</span>`;
      } else {
        newString += `${path}`;
      }
    });
    return newString;
  };

  const getSumFromAmountItems = (
    exclusionItems?: { amount: number; currency: string }[],
    baseAmount?: number | null
  ): string | undefined => {
    const { activeAccount } = useWalletStore();

    const exclusionItem = exclusionItems?.find(item => item.currency === activeAccount?.currency);

    if (exclusionItem) {
      return formatAmount(exclusionItem.currency, exclusionItem.amount);
    }

    if (baseAmount) {
      const { amount, currency } = getEquivalentFromBase(baseAmount, activeAccount?.currency);
      return formatAmount(currency, amount);
    }

    return undefined;
  };

  const addBetsyScript = (): HTMLElement => {
    const script = document.createElement('script');
    script.setAttribute('src', 'https://turboplatform-dev.betsy.gg/assets/sdk/init.js');
    script.setAttribute('defer', 'defer');
    document.head.append(script);
    return script;
  };

  const handleExternalLink = (url: string): void => {
    const router = useRouter();
    const { localizePath } = useProjectMethods();
    if (url.includes('http')) window.open(url, '_blank');
    else router.push(localizePath(url));
  };

  const awaitRefreshParallel = async (): Promise<string | null> => {
    return new Promise(resolve => {
      let iteration = 1;

      const checkStorage = (): void => {
        setTimeout(() => {
          if (iteration > 10) {
            localStorage.removeItem('refreshSession');
            resolve(null);
          }
          iteration += 1;
          const storageValue = localStorage.getItem('refreshSession');
          if (storageValue === 'loading') checkStorage();
          else resolve(storageValue);
        }, 500);
      };

      checkStorage();
    });
  };

  const getMinBonusDeposit = (bonusInfo: IBonus): { amount: number; currency: string } | undefined => {
    let minDeposit: { amount: number; currency: string } | undefined;

    const invoiceItems: IAmountRangeItem[] | undefined = bonusInfo.triggerConditions?.invoiceAmountItems;
    const baseCurrencyInvoiceFrom = bonusInfo.triggerConditions?.baseCurrencyInvoiceAmountFrom;
    const { activeAccount } = useWalletStore();

    if (invoiceItems?.length) {
      const currentCurrencyInvoiceItem = invoiceItems.find(
        invoiceItem => invoiceItem.currency === activeAccount?.currency
      );

      if (currentCurrencyInvoiceItem && currentCurrencyInvoiceItem.amountFrom) {
        minDeposit = { amount: currentCurrencyInvoiceItem.amountFrom, currency: currentCurrencyInvoiceItem.currency };
      }
    }

    if (!minDeposit && baseCurrencyInvoiceFrom) {
      minDeposit = getEquivalentFromBase(baseCurrencyInvoiceFrom, activeAccount?.currency);
    }

    return minDeposit;
  };

  const goBack = async () => {
    const router = useRouter();
    const excluded = ['/sign-up', '/login', '/forgot-password', '/reset-password'].map(path => localizePath(path));
    if (window.history.state.back && !excluded.includes(window.history.state.back)) {
      router.back();
    } else {
      await router.push(localizePath('/'));
    }
  };

  const levelNameWithoutNumber = (name: string): string => {
    const nameParts = name.split(' ');
    const lastPartNumber = Number(nameParts.at(-1));
    return isNaN(lastPartNumber) ? nameParts.join(' ') : nameParts.slice(0, -1).join(' ');
  };

  const getProvidersLogoSrc = (provider: IGameProvider): string => {
    const { public: config } = useRuntimeConfig();
    const { globalComponentsContent, defaultLocaleGlobalComponentsContent } = useGlobalStore();

    if (provider?.customImages?.['116x40'] && config.customerCdn) {
      const mainPath =
        provider?.customImages['116x40']?.['3x'] ||
        provider?.customImages['116x40']?.['2x'] ||
        provider?.customImages['116x40']['1x'];
      return `${config.customerCdn}${mainPath}`;
    }
    if (provider?.images?.['116x40'] && config.gamehubCdn) {
      const mainPath =
        provider?.images['116x40']?.['3x'] || provider?.images['116x40']?.['2x'] || provider?.images['116x40']['1x'];
      return `${config.gamehubCdn}${mainPath}`;
    }
    return (
      getContent(globalComponentsContent, defaultLocaleGlobalComponentsContent, 'providersSettings.defaultLogo') ||
      '/img/provider-empty-placeholder.svg'
    );
  };

  const openKeyboard = (parentElement: HTMLElement, duration = 3000): void => {
    const input = document.createElement('input');
    input.setAttribute('type', 'text');
    input.style.position = 'absolute';
    input.style.opacity = '0';
    input.style.height = '0';
    input.style.width = '0';
    input.style.zIndex = '-1';
    parentElement.appendChild(input);
    input.focus();

    setTimeout(() => {
      input.remove();
    }, duration);
  };

  const transformTournament = (tournament: CITournamentPage): ITournament => ({
    identity: tournament.identity,
    startAt: +dayjs(tournament.startAt),
    endAt: +dayjs(tournament.endAt),
    conditions: {
      gameCollections: [...(tournament?.gameCollections || [])],
    },
  });

  return {
    goBack,
    createValidationRules,
    getFormRules,
    preloaderDone,
    getImageUrl,
    localizePath,
    getNicknameFromEmail,
    getMainBalanceFormat,
    setPageMeta,
    sortByAlphabet,
    getContent,
    getLocalesContentData,
    setFormData,
    createFormRules,
    getRandomInt,
    initObserver,
    replaceContent,
    createSrcSet,
    getEquivalentFromBase,
    getSumFromAmountItems,
    addBetsyScript,
    handleExternalLink,
    awaitRefreshParallel,
    getMinBonusDeposit,
    formatAmount,
    levelNameWithoutNumber,
    getProvidersLogoSrc,
    openKeyboard,
    transformTournament,
  };
};
