<script lang="ts" setup>
  import { storeToRefs } from 'pinia';

  const { $oneSignal } = useNuxtApp();
  const profileStore = useProfileStore();
  const { profile, isLoggedIn } = storeToRefs(profileStore);
  const subscribe = async () => {
    try {
      await $oneSignal.Notifications.requestPermission();
      const externalId = profile.value?.id;
      if (externalId) {
        await $oneSignal.login(externalId);
      }
    } catch (e) {
      console.error(e);
    }
  };
  //TODO: Develop and rework modal according to designs
</script>

<template>
  <div v-if="isLoggedIn && !$oneSignal.Notifications.permission" class="push-notifications__button">
    <div @click="subscribe()">Subscribe</div>
  </div>
</template>
<style lang="scss" scoped>
  .push-notifications__button {
    position: fixed;
    right: 40px;
    bottom: 80px;
    display: flex;
    width: fit-content;
    padding: 10px 5px;
    background: var(--text-gold);
    border-radius: 20px;
    color: white;
    cursor: pointer;
  }
</style>
